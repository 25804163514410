.p-datatable .p-column-header-content {
  justify-content: center;
}
.img-border img {
  border-radius: 20px;
}

.tSubio,
.tBajo,
.tEstable {
  font-family: var(--font-regular);
  font-size: 1rem;
  text-align: center;
}

.tSubio {
  color: red;
}

.tBajo {
  color: green;
}

.tEstable {
  color: gray;
}
