.textPoint {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 10px;
  color: #404040;
}
.numberPoint {
  font-size: 1.5rem;
  color: var(--primary-color);
}
