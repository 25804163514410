.bannerH {
  margin-top: -53px !important;
}

.container-principal {
  background: var(--colorBk-content);
  background-image: url(../../assets/images/bk.svg);
  background-repeat: repeat-y;
  background-size: 100% !important;
  padding: 5% !important;
}
.container-principal img {
  width: 100%;
}

.container-principal ul {
  list-style: none !important;
}

.container-principal ul li {
  color: var(--text-color);
  font-size: 1.25rem;
}

.container-titan img {
  max-width: 90%;
}

.container-titan {
  margin: 0 auto !important;
  text-align: center !important;
}

.container-winner {
  margin: 0 auto !important;
  text-align: center !important;
}

.reistrateA {
  color: var(--text-color) !important;
  text-decoration: none !important;
}

.reistrateA:hover {
  color: var(--primary-color) !important;
}

.fullR {
  width: 100% !important;
  font-size: 1.5em !important;
}

@media only screen and (max-width: 1015px) {
  .bannerH {
    margin-top: -53px !important;
  }
}

@media only screen and (max-width: 600px) {
  .container-principal {
    background-image: none;
    padding: 5% 8% !important;
  }

  .bannerH {
    padding-top: 55px !important;
  }
}
