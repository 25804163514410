.footer {
  padding: 40px;
  background: var(--colorBk);
}
.footer p {
  color: var(--text-color-light);
  font-family: var(--font-medium);
}

.footer p a {
  color: var(--text-color-secondary);
  font-family: var(--font-bold-secondary);
}

.footer img {
  width: 95%;
  max-width: 200px;
}

/*Prubas Menu*/

.menuTitanes {
  background: var(--colorBk);
  height: 95px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.enlace {
  position: absolute;
  padding: 0px 10px;
}

.titanesLogo {
  height: 90px;
}

.menuTitanes ul {
  float: right;
  margin-right: 10px;
}

.menuTitanes ul li {
  display: inline-block;
  margin: 0 5px 0 5px;
  overflow: hidden;
}

.menuTitanes ul li a {
  color: var(--text-color-light);
  font-size: 1.2em;
  font-family: var(--font-bolditalic);
  padding: 0 13px;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

.menuTitanes li a.active,
.menuTitanes li a:hover {
  color: var(--primary-color);
  transition: 0.3s;
}

.menuTitanes ul li a img {
  max-width: 45px;
  height: 45px;
  border-radius: 50%;
}

.ultimo {
  transform: translateY(15px) !important;
}

.checkbtn {
  color: var(--colorBk);
  background: var(--primary-color);
  float: right;
  line-height: 55px;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.checkbtn i {
  font-size: 20px;
}

#check {
  display: none;
}

.imgUser {
  display: inline;
}

.imgUser img {
  max-width: 35px;
  border-radius: 50%;
}

.movilMenu {
  display: none !important;
}

.sinUserMe {
  padding-top: 25px !important;
}

.conUserMe {
  padding-top: 0px !important;
}

.miperfil {
  /* transform: translate(10px, -30px) !important; */
  margin-left: 10px !important;
  position: relative !important;
  top: -15px !important;
}

@media (max-width: 952px) {
  .enlace {
    padding-left: 20px;
  }
  nav ul li a {
    font-size: 16px;
  }
}

@media (max-width: 1444px) {
  .checkbtn {
    display: block;
  }

  .menuTitanes ul {
    position: absolute;
    width: 85%;
    background: var(--colorBk);
    top: 74px;
    left: -100%;
    text-align: left;
    transition: all 0.3s;
    padding: 0 0 0 30px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 0 0 5px 0;
    z-index: 1000;
  }

  .menuTitanes ul li {
    display: block;
    margin: 20px 35px 15px 0;
  }

  .menuTitanes ul li:not(:last-of-type) {
    border-bottom: 2px solid #cccccc;
  }

  .menuTitanes ul li a {
    font-size: 1.1em;
    padding-left: 0;
  }

  .menuTitanes li a:hover,
  .menuTitanes li a.active {
    background: none;
    color: var(primary-color);
  }

  #check:checked ~ ul {
    left: 0;
  }

  .escritorioMenu {
    display: none !important;
  }

  .movilMenu {
    display: inline !important;
  }

  .ultimo {
    padding: 0 0 10px 0 !important;
    margin-top: -15px !important;
  }

  .sinUserMe {
    padding-top: 5px !important;
  }
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .footer p,
  .footer p a {
    text-align: center;
  }

  .copy {
    text-align: left !important;
  }
}
