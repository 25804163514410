.mantenimientoC {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.mantenimientoC img {
  width: 100%;
  max-width: 900px;
}