.contenedorItems {
  display: flex !important;
  flex-wrap: wrap !important;
}

.itemMar {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px !important;
  overflow: hidden !important;
  height: 100%;
  min-height: 388px;
}

.nameItem {
  font-size: 1.25rem;
  font-family: var(--font-medium);
  color: var(--text-color-secondary);
  text-align: center;
  margin-bottom: 0 !important;
}

.nameItemPro {
  font-size: 1.1rem;
  font-family: var(--font-medium);
  color: var(--text-color-secondary);
  text-align: center;
  margin-bottom: 0 !important;
  min-height: 42px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.olimposItem {
  color: #999999;
  font-family: var(--font-bold-secondary);
  margin-top: 0 !important;
  font-size: 1.5rem !important;
  text-align: center;
}

.movilItem {
  text-align: center !important;
}

.movilItem img {
  width: 100%;
  max-width: 250px !important;
}

.movilItem p {
  text-align: left !important;
}

.direccionSend {
  border-radius: 10px !important;
  font-family: var(--font-regular) !important;
  font-weight: bold !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--secondary-color) !important;
  background: var(--secondary-color) !important;
}

.fullW {
  width: 100% !important;
}

.orderHistorial {
  background: linear-gradient(-45deg, #ffb500, #f3900a);
  border-radius: 50px;
  font-size: 1.2rem !important;
  font-family: var(--font-regular-secondary) !important;
  color: var(--text-color);
  padding: 5% 0;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  overflow: hidden;
}

.orderBag {
  background: linear-gradient(-45deg, #ffb500, #f3900a);
  font-family: var(--font-regular-secondary) !important;
  color: var(--text-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-responsive img {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  max-width: 280px;
  min-width: 150px;
  text-align: center !important;
  margin: 0 auto !important;
}

.nameOrder {
  font-size: 1.75rem !important;
  font-weight: bold !important;
  font-family: var(--font-medium);
  color: var(--text-color-secondary);
}

.directionOrder {
  font-weight: bold !important;
  font-family: var(--font-medium);
  color: var(--text-color-secondary);
}

.borderB {
  border-bottom: 1px solid #cccccc;
}

.orderDesc {
  color: var(--text-color-light) !important;
  font-family: var(--font-light) !important;
  font-size: 1.3rem;
  text-align: left !important;
}

.olimposOrder {
  color: #999999;
  font-family: var(--font-bold-secondary);
  margin-top: 0 !important;
  font-size: 1.75rem !important;
  text-align: left;
}

.resaltarC {
  color: var(--primary-color) !important;
}

.text-message-product {
  font-size: 10px;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .olimposItem {
    font-size: 1.25rem !important;
  }

  .textMovil p {
    text-align: center !important;
  }

  .itemMar {
    padding: 15px !important;
  }

  .orderHistorial {
    font-size: 0.9rem !important;
    padding: 10px 0;
  }

  .nameOrder,
  .orderDesc,
  .olimposOrder,
  .directionOrder {
    text-align: center !important;
  }

  .olimposOrder {
    font-size: 2.5rem !important;
  }

  .nameItem {
    font-size: 1.3rem !important;
  }
}

/*Tablet*/
@media only screen and (max-width: 768px) {
  .olimposItem {
    font-size: 1.3rem !important;
  }

  .nameItem {
    font-size: 1rem;
  }
}

/*Laptop*/
@media only screen and (max-width: 992px) {
}

/*Large Screen*/
@media only screen and (max-width: 1200px) {
}
