.btn-rank {
  width: 180px;
  height: auto;
  position: fixed;
  bottom: 15px;
  right: 15px;
  transition: all 300ms ease 0ms;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.btn-rank img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .btn-rank {
    width: 150px;
    right: 10px;
    bottom: 10px;
  }
}
