.sucursalCum {
  font-family: var(--font-regular-secondary);
  color: var(--text-color-secondary);
  font-size: 1.25rem;
  margin: 5px !important;
}

.vCumplientos {
  width: 95%;
  background: linear-gradient(-45deg, #ffb500, #f3900a);
  border-radius: 10px;
  font-size: 1.75rem !important;
  font-family: var(--font-bold-secondary) !important;
  color: var(--text-color);
  padding: 10px 15px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vCumplientos,
.vMetas {
  background: linear-gradient(-45deg, #ffb500, #f3900a);
  border-radius: 10px;
  font-size: 1.75rem !important;
  font-family: var(--font-bold-secondary) !important;
  color: var(--text-color);
  padding: 10px 15px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0 !important;
}

.vMetas {
  background: linear-gradient(-45deg, #ca142b, #e5383b);
  font-size: 2.5rem !important;
  padding: 5px 15px;
}

.grafico {
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.p-progressbar {
  background: linear-gradient(-45deg, #ca142b, #e5383b) !important;
  border-radius: 50px !important;
  height: 60px !important;
}

.p-progressbar .p-progressbar-label {
  font-size: 1.8rem !important;
  line-height: 3.8rem !important;
}

.p-progressbar .p-progressbar-value {
  background: linear-gradient(-45deg, #ffb500, #f3900a) !important;
  border-radius: 50px;
}

.metaF {
  color: var(--primary-color) !important;
  margin: 0 !important;
  font-family: var(--font-bold-secondary) !important;
  text-align: right !important;
  width: 100%;
}

.pi-flag {
  font-weight: bold !important;
}
/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .sucursalCum,
  .vCumplientos,
  .vMetas {
    text-align: center !important;
  }

  .vCumplientos {
    margin: 0 auto !important;
    width: 100%;
  }

  .p-progressbar {
    height: 50px !important;
  }

  .p-progressbar .p-progressbar-label {
    line-height: 2.9rem !important;
    font-size: 1.5rem !important;
  }
}

/*Tablet*/
@media only screen and (max-width: 768px) {
}

/*Laptop*/
@media only screen and (max-width: 992px) {
}

/*Large Screen*/
@media only screen and (max-width: 1200px) {
}
