.borderCanpaña {
  border-left: solid 3px #999999;
}

.tcampanas {
  color: var(--text-color-secondary) !important;
  font-family: var(--font-medium) !important;
  font-size: 1.3rem;
  text-align: left !important;
  cursor: pointer;
}

.tcampanas:hover {
  color: var(--primary-color) !important;
}

.subtcampanas {
  color: var(--text-color-light) !important;
  font-family: var(--font-light) !important;
  font-size: 1rem;
  text-align: left !important;
}

.textRes {
  color: var(--text-color-light) !important;
  font-family: var(--font-light) !important;
  font-size: 0.8rem;
  text-align: left !important;
}

.listComment {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.puntoS {
  color: #cccccc !important;
  padding: 0 10px;
}

.separador,
.separadorCom {
  border-top: solid 2px #cccccc;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
  width: 80%;
  text-align: left !important;
  margin-left: 0 !important;
}

.separadorCom {
  width: 100% !important;
}

.textWi {
  width: 100% !important;
  margin: 15px auto !important;
}

.dropListC,
.dropListC input {
  border-radius: 20px !important;
  padding: 0 10px !important;
}

.dropListC:focus {
  box-shadow: 0 0 7px 0.1rem var(--gray-variant) !important;
  border-color: var(--gray-variant) !important;
}

.textCampana {
  color: var(--text-color-light) !important;
  text-align: justify !important;
  font-size: 1.25rem;
}

.commentsText {
  border-radius: 10px !important;
  padding: 10px !important;
  border: 2px solid #cccccc !important;
  height: 180px;
  font-family: var(--font-medium) !important;
  overflow: hidden;
}

.commentsText textarea {
  height: 184px !important;
}

.avatarImg {
  text-align: center !important;
  margin: 0 auto !important;
}

.avatarImg > img {
  max-width: 60px !important;
  min-width: 30px !important;
  max-height: 60px !important;
  object-fit: scale-down !important;
}

.avatarImg img {
  width: 100% !important;
}

.comenntaUser {
  padding: 0 0 0 10px !important;
}

.activa {
  color: #009255 !important;
  font-family: var(--font-bold-secondary) !important;
}

.inactiva {
  color: var(--primary-color);
  font-family: var(--font-bold-secondary) !important;
}

.displayCampana {
  display: flex !important;
  align-items: center !important;
}

.contentResp {
  max-height: 300px;
  overflow-y: scroll !important;
}

.contentResp::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
  border: none;
}
.contentResp::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.contentResp::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.numLike {
  position: absolute !important;
  padding-top: 5px;
  font-size: 1rem !important;
}

/*Prue3ba Like*/
.like-button {
  color: #878787;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  filter: grayscale(100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
}

.like-button.liked {
  color: #ff6e6f;
  border-color: currentColor;
  filter: grayscale(0);
}

.like-button:hover {
  border-color: currentColor;
}

.like-icon {
  width: 18px;
  height: 18px;
  margin-top: 5px !important;
  display: inline-block;
  position: relative;
  margin-right: 0.25em;
  font-size: 1.5rem;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTAxIDQuNDE3UzguODk1LjIwNyA1LjExMS4yMDdjLTQuNDY1IDAtMTAuOTY3IDYuODQ2IDUuMDgyIDE3LjU5MkMyNS4yMzcgNy4wMyAxOS42NjUuMjAyIDE1LjUwMS4yMDJjLTQuMTYyIDAtNS40IDQuMjE1LTUuNCA0LjIxNXoiIGZpbGw9IiNGRjZFNkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==")
    no-repeat center;
  background-size: 100%;
  -webkit-animation: heartUnlike 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: heartUnlike 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon {
  -webkit-animation: heartPulse 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: heartPulse 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon [class^="heart-animation-"] {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuMTAxIDQuNDE3UzguODk1LjIwNyA1LjExMS4yMDdjLTQuNDY1IDAtMTAuOTY3IDYuODQ2IDUuMDgyIDE3LjU5MkMyNS4yMzcgNy4wMyAxOS42NjUuMjAyIDE1LjUwMS4yMDJjLTQuMTYyIDAtNS40IDQuMjE1LTUuNCA0LjIxNXoiIGZpbGw9IiNGRjZFNkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==")
    no-repeat center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 14px;
  opacity: 0;
}

.liked .like-icon [class^="heart-animation-"]::before,
.liked .like-icon [class^="heart-animation-"]::after {
  content: "";
  background: inherit;
  background-size: 100%;
  width: inherit;
  height: inherit;
  display: inherit;
  position: relative;
  top: inherit;
  left: inherit;
  opacity: 0;
}

.liked .like-icon .heart-animation-1 {
  -webkit-animation: heartFloatMain-1 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  animation: heartFloatMain-1 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.liked .like-icon .heart-animation-1::before,
.liked .like-icon .heart-animation-1::after {
  width: 12px;
  height: 10px;
  visibility: hidden;
}

.liked .like-icon .heart-animation-1::before {
  opacity: 0.6;
  -webkit-animation: heartFloatSub-1 1s 0.25s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-1 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
}

.liked .like-icon .heart-animation-1::after {
  -webkit-animation: heartFloatSub-2 1s 0.15s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-2 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  opacity: 0.75;
}

.liked .like-icon .heart-animation-2 {
  -webkit-animation: heartFloatMain-2 1s 0.1s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatMain-2 1s 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
}

.liked .like-icon .heart-animation-2::before,
.liked .like-icon .heart-animation-2::after {
  width: 10px;
  height: 8px;
  visibility: hidden;
}

.liked .like-icon .heart-animation-2::before {
  -webkit-animation: heartFloatSub-3 1s 0.25s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-3 1s 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  opacity: 0.25;
}

.liked .like-icon .heart-animation-2::after {
  -webkit-animation: heartFloatSub-4 1s 0.15s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: heartFloatSub-4 1s 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    both;
  opacity: 0.4;
}

@-webkit-keyframes heartPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@keyframes heartPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@-webkit-keyframes heartUnlike {
  50% {
    transform: scale(0.75);
  }
}

@keyframes heartUnlike {
  50% {
    transform: scale(0.75);
  }
}

@-webkit-keyframes heartFloatMain-1 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0);
  }

  50% {
    opacity: 1;
    transform: translate(0, -25px) rotate(-20deg);
  }
}

@keyframes heartFloatMain-1 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0);
  }

  50% {
    opacity: 1;
    transform: translate(0, -25px) rotate(-20deg);
  }
}

@-webkit-keyframes heartFloatMain-2 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0) scale(0);
  }

  50% {
    opacity: 0.9;
    transform: translate(-10px, -38px) rotate(25deg) scale(1);
  }
}

@keyframes heartFloatMain-2 {
  0% {
    opacity: 0;
    transform: translate(0) rotate(0) scale(0);
  }

  50% {
    opacity: 0.9;
    transform: translate(-10px, -38px) rotate(25deg) scale(1);
  }
}

@-webkit-keyframes heartFloatSub-1 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(13px, -13px) rotate(30deg);
  }
}

@keyframes heartFloatSub-1 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(13px, -13px) rotate(30deg);
  }
}

@-webkit-keyframes heartFloatSub-2 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(18px, -10px) rotate(55deg);
  }
}

@keyframes heartFloatSub-2 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(18px, -10px) rotate(55deg);
  }
}

@-webkit-keyframes heartFloatSub-3 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(-10px, -10px) rotate(-40deg);
  }

  100% {
    transform: translate(-50px, 0);
  }
}

@keyframes heartFloatSub-3 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(-10px, -10px) rotate(-40deg);
  }

  100% {
    transform: translate(-50px, 0);
  }
}

@-webkit-keyframes heartFloatSub-4 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(2px, -18px) rotate(-25deg);
  }
}

@keyframes heartFloatSub-4 {
  0% {
    visibility: hidden;
    transform: translate(0) rotate(0);
  }

  50% {
    visibility: visible;
    transform: translate(2px, -18px) rotate(-25deg);
  }
}

.activateC {
  color: var(--secondary-color) !important;
}

.dateComment {
  font-size: 0.7rem;
  color: var(--text-color-light);
  padding: 0 !important;
  margin: 0 !important;
}
/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .borderCanpaña {
    border: none;
  }

  .textCampana {
    font-size: 1.1rem;
  }

  .separador {
    width: 100%;
  }

  .tcampanas {
    font-size: 1rem;
  }

  .subtcampanas {
    font-size: 0.9rem;
  }
}

/*Tablet*/
@media only screen and (max-width: 768px) {
  .borderCanpaña {
    border: none;
  }
}
