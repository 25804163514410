/*Formulario Brilla*/

.pForm {
  color: var(--text-color-secundary) !important;
  font-family: var(--font-medium) !important;
  margin: 5px 0 !important;
  font-size: 1.2em;
  margin-left: 5px !important;
}

.pForm2 {
  color: var(--text-color-secundary) !important;
  font-family: var(--font-medium) !important;
  margin: 5px 0 !important;
  font-size: 1.2em;
  margin-left: 15px !important;
  margin-bottom: 0px !important;
}

.aliado {
  border-radius: 25px !important;
  padding: 5px 15px !important;
  border: 1px solid #cccccc !important;
  height: 40px;
}

.aliado p {
  color: var(--secondary-color);
  margin: 0 !important;
}

.card {
  background: var(--surface-card);
  border-radius: 10px;
  padding: 2rem 2rem;
  margin-bottom: 1rem;
}

.textForm,
.textForm input {
  border-radius: 30px !important;
}

.textForm:focus {
  box-shadow: 0 0 7px 0.1rem var(--gray-variant) !important;
  border-color: var(--gray-variant) !important;
}

.textFormLabel {
  left: 20px !important;
}

.textFormIcon {
  right: 20px !important;
  color: var(--primary-color) !important;
  z-index: 1000 !important;
}

.p-inputtextarea-resizable {
  padding: 15px !important;
}

.checked {
  color: var(--text-color-light) !important;
  margin: 0 10px !important;
  cursor: pointer;
  font-family: var(--font-medium) !important;
}

.p-calendar input {
  border-radius: 30px !important;
}

.btnlogin {
  background: var(--color-secondary) !important;
  color: var(--color-white);
  border: 1px solid var(--color-primary-variant) !important;
  font-weight: bold;
  width: 200px !important;
  height: 40px;
  border-radius: 20px !important;
  font-size: 1rem !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--secondary-color) !important;
  background: var(--secondary-color) !important;
}

.mensajeL {
  color: var(--primary-color) !important;
  font-size: 1.25rem;
  text-align: center;
}

.historyForm {
  padding: 15px !important;
}

.field-checkbox > label,
.field-radiobutton > label {
  font-family: var(--font-regular-secondary) !important;
}

@media screen and (max-width: 600px) {
  .btnlogin {
    width: 180px !important;
    height: 50px;
    border-radius: 20px !important;
    font-size: 1.5rem !important;
  }

  .mensajeL {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1224px) {
  .card {
    margin: 1rem 16rem !important;
  }
}
