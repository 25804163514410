@font-face {
  font-family: "SSPR";
  src: local("SourceSansPro Regular"),
    url(./assets/ttf/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SSPB";
  src: local("SourceSansPro Bold"),
    url(./assets/ttf/SourceSansPro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SSPL";
  src: local("SourceSansPro Light"),
    url(./assets/ttf/SourceSansPro-Light.ttf) format("truetype");
}

/*StRyde*/
@font-face {
  font-family: "STRB";
  src: local("StRyde Bold"),
    url(./assets/ttf/StRyde-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "STRBI";
  src: local("StRyde BoldItalic"),
    url(./assets/ttf/StRyde-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "STRR";
  src: local("StRyde Regular"),
    url(./assets/ttf/StRyde-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "STRM";
  src: local("StRyde Medium"),
    url(./assets/ttf/StRyde-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "TWHA";
  src: local("TwistedHalloween"),
    url(./assets/ttf/TwistedHalloween.ttf) format("truetype");
}

:root {
  --colorBk: #ffffff !important;
  --colorBk-content: #dc0b24;
  /* --colorBk-content: #f45201; */
  --primary-color: #ea0029 !important;
  --secondary-color: #ffb600 !important;
  --tertiary-color: #009245 !important;
  --text-color: #ffffff !important;
  --text-color-secondary: #4d4d4d !important;
  --text-color-light: #808080;
  --gray-variant: #869093;
  --font-regular: "SSPR";
  --font-regular-secondary: "STRR";
  --font-bold: "STRB";
  --font-bold-secondary: "SSPB";
  --font-bolditalic: "STRBI";
  --font-Light: "SSPL";
  --font-medium: "STRM";
  --font-halloween: "TWHA";
  --border-radius: 30px !important;
  --focus-ring: 0 0 0 0.1rem #f5cd68 !important;
  --content-padding: 1.25rem !important;
  --inline-spacing: 1.8em !important;
}

body {
  background: var(--colorBk);
  font-family: var(--font-regular) !important;
  margin: 0 !important;
}

h1 {
  /* color: var(--secondary-color); */
  color: var(--text-color);
  /* font-family: var(--font-halloween); */
  font-family: var(--font-bold);
  text-align: center;
  font-size: 4rem !important;
  /* text-transform: uppercase; */
}

h2 {
  color: var(--primary-color);
  font-family: var(--font-bolditalic);
  font-size: 2.2rem !important;
}

h3 {
  color: var(--primary-color);
  font-family: var(--font-bolditalic);
  font-size: 2rem !important;
}

p {
  color: var(--text-color);
  text-align: justify;
  font-size: 1.3rem;
}

.guias {
  border: solid 1px #000 !important;
}

.sinP {
  padding: 0 !important;
}

.sinPT {
  padding-top: 0 !important;
}

.sinM {
  margin: 0 !important;
}

.visiblesm {
  display: none !important;
}

.centrar {
  margin: 0 auto !important;
  text-align: center !important;
}

/*Botones*/
.p-button-brilla,
.p-button-vermas,
.p-button-cerrar,
.p-button-video {
  color: var(--text-color) !important;
  background: var(--secondary-color) !important;
  border: var(--secondary-color) !important;
  font-family: var(--font-bolditalic) !important;
  border-radius: 50px !important;
  font-size: 1.25rem !important;
  padding: 1.3% 2.5rem !important;
  transition-duration: 0.4s !important;
  text-align: center !important;
  margin: 0 auto !important;
  display: block !important;
}

.p-button-brilla:hover,
.p-button-video:hover {
  background: var(--primary-color) !important;
  transition: all 0.4s !important;
}

.p-button-brilla:active,
.p-button-video:active {
  background: var(--red-700) !important;
}

.p-button-video {
  margin: 0 !important;
}

.p-button-vermas {
  color: var(--primary-color) !important;
  background: var(--secondary-color) !important;
  border: var(--secondary-color) !important;
  max-width: 280px;
}

.p-button-cerrar:hover,
.p-button-cerrar:active {
  color: var(--secondary-color) !important;
  transition: all 0.4s !important;
  background: var(--text-color) !important;
}

.p-button-vermas:hover {
  color: var(--text-color) !important;
  transition: all 0.4s !important;
}

.p-button-vermas:active {
  color: var(--text-color) !important;
  background: var(--yellow-700) !important;
}

.p-button-responder {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: var(--text-color-light) !important;
  font-family: var(--font-light) !important;
  cursor: pointer !important;
}

.p-button-responder:hover,
.p-button-responder:active {
  color: var(--primary-color) !important;
  transition: all 0.4s !important;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.modalP {
  color: var(--text-color-secundary);
  font-family: var(--font-medium) !important;
  text-align: left !important;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: var(--colorBk-content) !important;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.separd {
  border-bottom: 2px solid #ccc !important;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .visiblemd {
    display: none !important;
  }

  .visiblesm {
    display: flex !important;
  }

  .modalP {
    font-size: 1rem !important;
  }

  h1 {
    font-size: 2.8rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  .p-button-brilla,
  .p-button-vermas,
  .p-button-cerrar {
    font-size: 1rem !important;
    padding: 0.4rem 1.5rem !important;
  }

  .p-button-video {
    margin: 0 auto !important;
  }

  h3 {
    font-size: 1.5rem !important;
  }

  p {
    font-size: 1.1rem;
  }
}

.p-inputnumber-button {
  background: var(--secondary-color) !important;
  color: #ffffff !important;
  border-color: var(--secondary-color) !important;
}

/*Tablet*/
@media only screen and (max-width: 768px) {
}

/*Laptop*/
@media only screen and (max-width: 992px) {
}

/*Large Screen*/
@media only screen and (min-width: 1200px) {
  .p-button-brilla,
  .p-button-vermas {
    padding: 0.5rem 2.5rem !important;
  }

  .p-button-cerrar {
    padding: 3% 2.5rem !important;
  }
}
