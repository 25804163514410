.olimpos,
.tMisiones,
.tTotal {
  background: linear-gradient(-45deg, #ca142b, #e5383b);
  border-radius: 50px;
  font-size: 1.75rem !important;
  font-family: var(--font-regular-secondary) !important;
  color: var(--text-color);
  padding: 1% 0;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tTotal {
  text-align: center;
}

.imgInsignias {
  max-width: 450px;
  min-width: 150px;
  padding: 0 15px !important;
  margin-top: -10% !important;
}

.imgInsigniasModal {
  max-width: 100%;
  margin-top: -13% !important;
}

.nameIng {
  color: var(--text-color-light);
  font-family: var(--font-regular-secondary) !important;
  font-size: 1.25rem !important;
  text-align: center;
  margin: -3% 0 0 0 !important;
}

.tMisiones,
.tTotal {
  border-radius: 0 !important;
  margin: 0 !important;
  font-size: 1.2rem !important;
  text-align: left !important;
  padding: 10px 15px !important;
}

.tTotal {
  text-align: center !important;
}

.textmisiones {
  font-family: var(--font-regular-secondary) !important;
  color: var(--text-color-light);
  font-size: 1rem !important;
  margin: 0 !important;
  padding: 5px 10px !important;
}

.imgMe {
  width: 100% !important;
  max-width: 60px;
}

.borderIrr {
  border-radius: 0 0 5px 5px !important;
}

/*insignias Acumuladas*/

.contenedorIng {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px !important;
  padding: 25px !important;
}

.p-tabview-title {
  font-family: var(--font-regular-secondary) !important;
  font-size: 1.1rem !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.tAcumulada {
  font-family: var(--font-regular-secondary) !important;
  color: var(--text-color-secondary);
  font-size: 1rem !important;
  text-align: center !important;
}

.userIng {
  background: #f5f5f5;
  text-align: center;
}

.userIng img {
  width: 100% !important;
  padding: 5px;
  cursor: pointer;
  max-width: 270px;
  text-align: center;
}

.padre-flex {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.borderIng {
  border-left: 4px solid var(--text-color) !important;
  border-right: 4px solid var(--text-color) !important;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .olimpos {
    font-size: 1.5rem !important;
    padding: 2% 0;
  }

  .nameIng {
    font-size: 1rem !important;
  }

  .contenedorIng {
    padding: 15px !important;
  }

  .p-tabview-nav-link {
    padding: 10px !important;
  }

  .p-tabview-title {
    font-size: 0.8rem !important;
    padding: 0px !important;
  }

  .tAcumulada {
    font-size: 0.75rem !important;
  }

  .borderIng {
    border: none !important;
  }
}

/*Tablet*/
@media only screen and (max-width: 768px) {

  .tMisiones,
  .tTotal {
    font-size: 0.9rem !important;
  }
}

/*Laptop*/
@media only screen and (max-width: 992px) {}

/*Large Screen*/
@media only screen and (max-width: 1200px) {}