.p-dialog-header-icons {
  background: var(--secondary-color);
  border-radius: 50% !important;
}

.p-dialog-header-close-icon {
  color: var(--text-color) !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  color: var(--primary-color);
  font-family: var(--font-bold);
  font-size: 1.5rem !important;
}

.player-wrapper {
  position: relative !important;
  padding-top: 56.25% !important;
}

.react-player {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
