.contentIng {
  background: var(--colorBk);
  padding: 30px !important;
  border-radius: 30px;
}

.centrar {
  margin: 0 auto !important;
  text-align: center !important;
}

.centrar img {
  max-width: 500px !important;
}

.tableTitle {
  font-family: var(--font-regular) !important;
  font-size: 1em !important;
  color: var(--text-color-secondary) !important;
  background: #f2f2f2 !important;
}

.tableData {
  color: var(--text-color-light) !important;
  font-family: var(--font-light) !important;
  font-size: 1rem;
  text-align: left !important;
}

.p-datatable-thead tr th {
  background: #f2f2f2 !important;
}

.linkTable {
  color: var(--text-color-secondary) !important;
  font-size: 1rem;
  text-align: left !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 50% !important;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .contentIng {
    padding: 20px !important;
  }
}