.carousel-demo .product-item .product-item-content {
  text-align: center;
}

.carousel-demo .product-item .product-image {
  width: 100%;
}

.p-galleria-indicator button {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.p-highlight button {
  background-color: var(--secondary-color) !important;
}

.p-galleria-indicators{
  background: var(--colorBk-content);
}