/*Formulario Brilla*/

.contentForm {
  background: var(--colorBk);
  border-radius: var(--border-radius);
  padding: 30px !important;
}

.contentForm h2 {
  text-align: center;
  margin-top: 0 !important;
}

.card {
  background: var(--surface-card);
  border-radius: 10px;
  padding: 2rem 2rem;
  margin-bottom: 1rem;
}

.textForm,
.textForm input {
  border-radius: 30px !important;
}

.textForm:focus {
  box-shadow: 0 0 7px 0.1rem var(--gray-variant) !important;
  border-color: var(--gray-variant) !important;
}

.textFormLabel {
  left: 20px !important;
}

.textFormIcon {
  right: 20px !important;
  color: var(--primary-color) !important;
  z-index: 1000 !important;
}

.checked {
  color: var(--text-color-light) !important;
  margin: 0 10px !important;
  cursor: pointer;
  font-family: var(--font-medium) !important;
  text-decoration: underline;
}

.p-calendar input {
  border-radius: 30px !important;
}

.btnlogin {
  background: var(--color-secondary) !important;
  color: var(--color-white);
  border: 1px solid var(--color-primary-variant) !important;
  font-weight: bold;
  width: 200px !important;
  height: 40px;
  border-radius: 20px !important;
  font-size: 1rem !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.recuperarPass {
  color: #0000ff !important;
  cursor: pointer;
  font-family: var(--font-medium);
  font-size: 1rem;
  text-decoration: underline !important;
  text-align: left !important;
}

.recuperarPass2,
.recuperarPass2 a {
  cursor: pointer;
  font-family: var(--font-medium);
  text-decoration: underline !important;
  text-align: right !important;
  font-size: 1rem;
  color: #0000ff !important;
}

@media screen and (max-width: 600px) {
  .btnlogin {
    width: 180px !important;
    height: 50px;
    border-radius: 20px !important;
    font-size: 1.5rem !important;
  }

  .contentForm {
    padding: 30px 25px !important;
  }

  .recuperarPass,
  .recuperarPass2 {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 1224px) {
  .card {
    margin: 1rem 16rem !important;
  }
}
