/*Medias Query*/
.desPdf {
  color: var(--primay-text-color) !important;
  text-decoration: none !important;
  font-family: var(--font-bolditalic) !important;
  font-size: 2rem;
}

.titleNoti {
  font-size: 5rem;
}

.noticiaTitle {
  font-size: 3.5rem;
}

.subnoticiaTitle {
  font-size: 2.5rem !important;
}

.iframeNoti {
  height: 1020px;
}

.downloads {
  text-align: center !important;
  padding: 15px 0 !important;
}

.fotoNoti {
  border-radius: 10px !important;
  padding: 10px !important;
  border: 2px solid #cccccc !important;
  height: 240px;
  font-family: var(--font-medium) !important;
  overflow: hidden;
  text-align: center;
}

.fotoNoti img {
  padding: 10px !important;
  width: 100% !important;
  max-width: 180px !important;
  cursor: pointer !important;
}

.notiText {
  border-radius: 10px !important;
  padding: 10px !important;
  border: 2px solid #cccccc !important;
  height: 240px;
  font-family: var(--font-medium) !important;
  overflow: hidden;
}

.notiText textarea {
  height: 244px !important;
}

.adjunto {
  font-family: var(--font-bold);
  color: var(--primary-color) !important;
  text-align: center !important;
  cursor: pointer !important;
}

/*Adjuntar Pruebas*/
.SubirFoto {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  line-height: normal;
  font-size: 100%;
  margin: 0;
}

.tNotibrilla {
  color: var(--text-color-secondary) !important;
  font-family: var(--font-medium) !important;
  font-size: 1.3rem;
  text-align: left !important;
  cursor: pointer;
}

.tNotibrilla:hover {
  color: var(--primary-color) !important;
}

.activateH{
  color: var(--secondary-color) !important;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .titleNoti {
    font-size: 3rem;
  }

  .iframeNoti {
    height: 600px;
  }

  .noticiaTitle {
    font-size: 2.5rem;
  }

  .subnoticiaTitle {
    font-size: 1.5rem !important;
  }

  .fotoNoti {
    height: 280px;
  }

  .tNotibrilla {
    font-size: 1rem;
  }
}

/*Mobile*/
@media only screen and (max-width: 600px) {
}

/*Tablet*/
@media only screen and (max-width: 768px) {
}
