.fotoProfile {
  border-radius: 10px !important;
  padding: 20px !important;
  border: 2px solid #cccccc !important;
  font-family: var(--font-medium) !important;
  text-align: center;
}

.fotoProfile img {
  padding-bottom: 5px !important;
  width: 100% !important;
  max-width: 270px !important;
  cursor: pointer !important;
}

.fotoProfile p {
  color: var(--text-color-secondary);
  margin: 0 !important;
  text-align: left;
}

.profileDes {
  font-family: var(--font-regular) !important;
  color: var(--text-color-light) !important;
  font-size: 1.25rem !important;
}

.adjuntoProfile,
.adjuntoProfile a,
.adjuntoProfileDisable {
  font-family: var(--font-bold);
  color: var(--primary-color) !important;
  text-align: center !important;
  cursor: pointer !important;
  font-size: 1.2rem !important;
  text-decoration: none !important;
  transition-duration: 0.4s !important;
}

.adjuntoProfileDisable {
  color: var(--text-color-secondary) !important;
}

.adjuntoProfile:hover {
  color: var(--secondary-color) !important;
}

.adjuntoProfile i,
.adjuntoProfileDisable i {
  font-size: 1.4rem;
}

.cerrarSesion {
  position: absolute;
  top: 110px;
  right: 10px;
}

.aliadosPerfil {
  color: var(--secondary-color) !important;
  font-weight: bold !important;
}

/*Pruebas*/
/* .sprite-animal.ape {
  width: 200px;
  height: 200px;
  background-image: url(../../assets/images/explosion.png);
  animation: ape_jam 1s steps(6) infinite !important;
} */
/* .sprite-animal {
  background: transparent no-repeat;
  position: relative;
}

.sprite-animal.sonic1{
  width: 45px;
    height: 49px;
    background-image: url(../../assets/images/sonic1.png);
    animation: sonic1_ani 0.5s steps(5) infinite !important;
}

@keyframes sonic1_ani {
  from {
    background-position: 0px;
  }

  to {
    background-position: -233px;
  }
} */

/* .sprite-animal.ape:active {
  animation: ape_jam 1s steps(6) infinite !important;
} */

@keyframes ape_jam {
  from {
    background-position: 0px;
  }

  to {
    background-position: -1200px;
  }
}

/*Prueba 2*/
/* .sprite-animal {
  background: transparent no-repeat;
  position: relative;
}

.sprite-animal.sonic1 {
  width: 104px;
  height: 184px;
  background-image: url(../../assets/images/sonic4.avif);
  animation: sonic1_ani 1s steps(8) infinite !important;
} */

@keyframes sonic1_ani {
  from {
    background-position: 0px;
  }

  to {
    background-position: -832px;
  }
}

/*Fotos a sucursal*/
.docImgL,
.docImgR {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.boxImg {
  width: 300px;
  height: 200px;
  border-radius: 25px;
  border: 3px solid #cccccc;
  overflow: hidden;
}

.boxInfo {
  width: 300px;
  overflow: hidden;
}

.boxImg img {
  width: 100%;
  height: auto;
}

.p-button-sucursal {
  padding: 10px 20px !important;
  width: 100%;
  max-width: 280px;
  border-radius: 15px !important;
  cursor: pointer;
}

@supports(object-fit: cover) {
  .boxImg img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  .adjuntoProfile {
    font-size: 1.1rem !important;
  }

  .adjuntoProfile i {
    font-size: 1rem;
  }

  .cerrarSesion {
    position: absolute;
    top: 100px;
    right: 10px;
  }

  .boxImg {
    width: 250px;
    height: 150px;
    border-radius: 25px;
    border: 3px solid #cccccc;
    overflow: hidden;
  }

  .boxInfo {
    width: 250px;
    overflow: hidden;
  }
}